import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios' 
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.use(VueAwesomeSwiper);

Vue.use(ElementUI);

//设置超时时间
axios.defaults.timeout = 5000


// 添加请求拦截器
axios.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // 请求头里面放上token信息
    let token = sessionStorage.getItem('token');
    if(token){
        config.headers['token'] = token
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//配置默认的url请求路径，可以直接修改，子组件直接调用
axios.defaults.baseURL = 'https://langjian.cnshangji.cn/'

//在其他组件中直接使用this.$http.post/get等调用
//配置axios的全局设置
Vue.prototype.$http = axios



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
