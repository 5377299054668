<template>
    <div>
        <div class="nav" @click="openNav(), showPopup()">
            <div class="nav_img">
                <!-- <img  src="../assets/homeimg/nav.png" alt=""> -->
                <img  src="../assets/homeimg/1111.png" alt="">
            </div>
            
            <div class="nav_text">
                <p>导航</p>
                <p>MENU</p>
            </div>
        </div>
        <!-- 弹出层 -->
        <van-popup v-model="show" position="left" :style="{ height: '100vh', }">
            <van-sidebar v-model="activeKey">
                <van-sidebar-item v-for="(item, i) in listNav" :key="i" @click="NavTo(item)" :title="item.name" />
            </van-sidebar>
        </van-popup>
    </div>
   
</template>

<script>
    export default {
        name: 'Nav',
        
        props: {
            activeKey:{
                type: Number,
                default: 0
            }
        },

        data() {
            return {
                show: false,
                // activeKey: 0,
                listNav: [
                    {
                        name: '网站首页',
                        navTo: '/'
                    },
                    {
                        name: '关于我们',
                        navTo: '/about'
                    },
                    {
                        name: '服务范围',
                        navTo: '/fuwu'
                    },
                    {
                        name: '典范项目',
                        navTo: '/dianfan'
                    },
                    {
                        name: '朗建之窗',
                        navTo: '/langwin'
                    },
                    {
                        name: '精英招聘',
                        navTo: '/jingpin'
                    }
                ]
            }
        },
        methods: {
            openNav(){
                this.$emit('openNav', '123')
            },
            showPopup() {
                this.show = true;
            },
            NavTo(item){
                this.$router.push(item.navTo)
            }
        }

    }
</script>


<style lang="scss">

.nav {
    display: flex;
    // box-shadow: 4px 4px 5px 2px #333;
    // background: #000;
    align-items: flex-start;

    .nav_img {
        // width: 90px;
        // height: 40px;
        width: 44px;
        height: 30px;
        padding-top: 5px;
        margin-right: 10px;

        img {
            display: inline-block;
            width: 100%;
        }
    }

    .nav_text {
        font-size: 16px;
        color: #fff;
        vertical-align: top;
    }

}
</style>