<template>

  <div class="swipertwo">
    <!-- <div class="nav-p"  @click="showPopup">
        <Nav @openNav="openNav"></Nav>
    </div> -->

    
    <swiper id="swiperBox" v-bind:options="swiperOption" ref="mySwiper" v-if="imgArray.length>0">
      <swiper-slide class="swiper-slide" v-for="(item, i) in imgArray" :key="i">
        <div
          class="bgc"
          :style="{ 'background-image': 'url(' + item.src + ')' }"
        >
       
          <div class="rotate">
            <div class="box">
                <p class="title">{{ item.title }}</p>
                <p>{{item.content}}</p>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="dian">
			<div class="swiper-pagination1"></div>

	</div>
  </div>
</template>

<script>
import Nav from '../components/Nav.vue'
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "SwiperOne",
  components: {
    swiper,
    swiperSlide,
    Nav
  },

  props: {},

  data() {
    return {
      imgArray: [],

      swiperOption: {
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        direction: "horizontal", //水平方向移动
        grabCursor: false, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        height: window.innerHeight, // 高度设置，占满设备高度  如果加导航的话在此基础上减6
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
        spaceBetween: 30,
        // effect: 'fade', // 翻页效果
        //是否循环 true 是，false 否
        loop: true,
        //在loop 模式下，将用空白slide 填充slide 数量不足的组{loop和loopFillGroupWithBlank是一个组合}
        loopFillGroupWithBlank: true,
        observer:true,

        autoplay: true,
        pagination: {
          el: ".swiper-pagination1", //点击切换
          clickable: true,
        },
        on: {
          //监听滑动切换事件，返回swiper对象
          slideChange: () => {
            let swiper = this.$refs.mySwiper.swiper;
            // console.log("鼠标", swiper);
            // console.log(swiper.activeIndex); //滑动打印当前索引
          },
        },
        
      },

      show: false,
    // activeKey: 0,
        listNav: [
            {
                name: '网站首页',
                navTo: '/'
            },
            {
                name: '关于我们',
                navTo: '/about'
            },
            {
                name: '服务范围',
                navTo: '/fuwu'
            },
            {
                name: '典范项目',
                navTo: '/dianfan'
            },
            {
                name: '朗建之窗',
                navTo: '/langwin'
            },
            {
                name: '精英招聘',
                navTo: '/jingpin'
            }
        ]
       
    };
  },

  computed: {
    swiper() {
        return this.$refs.mySwiper.swiper;
    }
  },

  mounted() {
    this._getList();
    
  },

  methods: {
    _getList() {
      this.$http
        .get(`home/homenew2`)
        .then((response) => {
        //   console.log(response.data.message);
          this.imgArray = response.data.message;


        })
        .catch(function (error) {
          window.console.log(error);
        });
    },

    openNav(val){
        // console.log(val)
        
    },

    showPopup() {
                this.show = true;
            },
            NavTo(item){
                this.$router.push(item.navTo)
            }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.swipertwo {
    position: relative;
}

.nav-p {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 999;
}
.dian {
    width: 150px;
    position: absolute; 
    bottom: 15px; 
    z-index: 10; 
    left: 50%; 
   transform:translate(-50%,-50%);
}

.bgc {
  width: 100%;
  height: 100vh;
  // background-image: url(../assets/homeimg/home.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.rotate {
    transform: rotate(90deg);
    transform-origin:0 0;
    color: #000;
    z-index: 99999;
    position: absolute;
    left: 88px;
    top: 15px;

    .box {
         width: 210%;
        p {
            font-size: 14px;
            color: #fff;
            width: 100%;
            min-height: 30px;
        }

        .title {
            font-size: 30px;
        }
    }

    
}
</style>
