<template>
  <div class="home">
    <SwiperOne></SwiperOne>
  </div>
</template>

<script>
import SwiperOne from '../components/SwiperOne.vue'

export default {
  name: 'HomeView',
  components: {
    SwiperOne
  },
  data() {
    return {
        
    }
  },
  
}
</script>
