<template>
  <div class="hello">
    <div class="nav_box">
                <Nav @openNav="openNav"></Nav>
    </div>
    <swiper id="swiperBox" v-bind:options="swiperOption" ref="mySwiper">
      <swiper-slide class="swiper-slide slide-one">
        <div class="headerqq">
           <div class="title">
               <img src="../assets/homeimg/logolj.png" alt="">
           </div>
           
        </div>
        <div class="con_text">   
            <!-- <div class="txt_cn">
                营造卓越环境 成就美好生活
            </div>
            <div class="txt_en">CREATE AN EXCELLENT ENVIRONMENT TO ACHIEVE A BETTER LIFE</div> -->
            <img class="pina" src="../assets/homeimg/pina.png" alt="">
            <img class="pinb" src="../assets/homeimg/pinb.png" alt="">
            <img class="pinc" src="../assets/homeimg/pinc.png" alt="">
            <img class="pind" src="../assets/homeimg/pind.png" alt="">
            <p class="p1">为客户创造效益、为社会创造价值、为员工创造机会</p>
            <p class="p2">并以“质量、效率、服务、创新”作为设计管理的基本原则</p>
        </div>

        <div class="bottom">
            <div class="mouse">
                <p class="p1"></p>
                <div class="img_box">
                    <img src="../assets/homeimg/mouse.png" alt="">
                </div>
                <p class="p2">向下滑动查看案例</p>
            </div>
            <div class="contact">
                <!-- <i class="el-icon-phone-outline"></i>
                <p class="p1" style="margin-right: 10px">0535-6715596</p> -->
                <div>
                    <i class="el-icon-location-information"></i>
                    <p class="p1">上海公司</p>
                </div>
                <div>
                    <i class="el-icon-location-information"></i>
                    <p class="p1">济南公司</p>
                </div>
                <div>
                    <i class="el-icon-location-information"></i>
                    <p class="p1">烟威公司</p>
                </div>
             
            </div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide slide-two">
        <div class="swiper_two" >
          <SwiperTwo></SwiperTwo>
        </div>
      </swiper-slide>
    </swiper>  
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import SwiperTwo from '../components/SwiperTwo.vue'
import Nav from '../components/Nav.vue'

export default {
  name: 'SwiperOne',
  components: {
    swiper,
    swiperSlide,
    SwiperTwo,
    Nav
  },

  props: {
    
  },
  data(){
    return {
        swiperOption: {
            notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
            direction: "vertical", //水平方向移动
            grabCursor: false, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
            setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
            autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
            slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
            mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
            mousewheelControl: true, //同上
            height: window.innerHeight, // 高度设置，占满设备高度  如果加导航的话在此基础上减6
            resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
            observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新
            spaceBetween: 1000,
            // pagination: {
            // el: ".swiper-pagination", //点击切换
            // clickable: true,
            
            // },
            // on: {
            //     //监听滑动切换事件，返回swiper对象
            //     slideChange: () => {
            //         let swiper = this.$refs.mySwiper.swiper;
            //         console.log("鼠标", swiper);
            //         console.log(swiper.activeIndex); //滑动打印当前索引
            //     },
            // },
        },
        show: false,
    }
  },
  computed: {
    swiper() {
        return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    openNav(val){
        // console.log(val)
        
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

        .nav_box {
            position: fixed;
            right: 20px;
            z-index: 999;
            padding-top: 12px;
            
        }

.slide-one {
    background-image: url(../assets/homeimg/home4.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;

    .headerqq {
        width: 100%;
        height: 109px;
        padding-left: 20px;
        padding-top: 12.5px;
        padding-right: 17.5px;
        display: flex;
        justify-content: space-between;
        // background-image: url(../assets/homeimg/tit_bgc.png);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: left center;

        .title {
            width: 100px;

            img {
                width: 100%;
            }
        }

        // .nav_box {
            
        //     padding-top: 12px;
            
        // }
    }

    .con_text {
        position: relative;
        margin-top: 135px;
        padding-left: 20px;
        color:#fff;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: rgba(19,19,24,.6);
        height: 30%;

        img {
            display: block;
            position: absolute;
            width: 29%;
        }

        .pina {
            top: 0;
            left: 0;
            
        }

        .pinb {
            bottom: 0;
            left: 0;
        }
        .pinc {
            bottom: 0;
            left: 0;
        }
        
        .pind {
            right: 20px;
            top: 30%;
            transform: translate(0, -50%);
            width: 78%;
        }
        .p1 {
            font-size: 10px;
            position: absolute;
            right: 20px;
            top: 47%;
            transform: translate(0, -50%);
        }
        .p2 {
            font-size: 10px;
            position: absolute;
            right: 20px;
            top: 60%;
            transform: translate(0, -50%);
        }


        .txt_cn {
            width: 100%;
            font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
            font-size: 30px;
            

            &::after {
                content: '';
                display: block;
                margin-top: 20px;
                width: 43px;
                height: 3px;
                background: #d90a1e;
            }
        }

        .txt_en {
            margin-top: 20px;
            width: 300px;
            font-size: 15px;
        }

        
    }
    
    .bottom {
        color: #fff;
        width: 100%;
        position: absolute;
        bottom: 10px;
        left: 0;

        .mouse {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .p1 {
                width: 1px;
                height: 38px;
                background-color: #fff;
            }

            .img_box {
                width: 23px;
                margin-top: 10px;

                img {
                    width: 100%;
                }
            }

        }

        .contact {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            div {
                display: flex;
                align-items: center;

                i {
                    color: #d90a1e;
                }
                
                .p1 {
                    font-size: 12px;
                    // transform: scale(0.8);
                }
            }

            
            
        }

    }
}


</style>
